<template>
  <div>
    <b-modal ref="my-modal" class="rounded-0" size="lg" centered title="Filtreleme Güncelle">
      <b-card-header>
        <b-form-group>
          <b-input-group>
            <v-select
              v-model="secilen_variant"
              :options="variantlar"
              :reduce="(vars) => vars.k_no"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
              style="min-width: 300px"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik['tr'].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik['tr'].baslik }}
              </template>
            </v-select>
            <b-button squared variant="success" @click="$emit('yeniOzellikEkle', secilen_variant)"> Filtre Özellik Ekle</b-button>
          </b-input-group>
        </b-form-group>
      </b-card-header>
      <b-card-text v-if="updated">
        <draggable v-model="form.ayar" handler=".draggable-task-handle" draggable=".item">
          <KategoriFiltrelemeItem v-for="(form, i) in form.ayar" :key="i" :form="form" class="item" />
        </draggable>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="$emit('save', true)">
            <i class="fad fa-save" />
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import vSelect from 'vue-select';
import { ref, watch } from '@vue/composition-api';
import KategoriFiltrelemeItem from './KategoriFiltrelemeItem.vue';
export default {
  components: {
    vSelect,
    draggable,
    KategoriFiltrelemeItem,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    variantlar: {
      type: Array,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    expo.updated = ref(false);
    expo.secilen_variant = ref(null);

    expo.disabled = (val) => {
      return val == 'arama' ? false : true;
    };

    expo.toggleModal = () => {
      context.refs['my-modal'].toggle('#toggle-btn');
      context.emit('closeModal', true);
    };

    watch(
      props,
      (val) => {
        if (val.openModal == true) {
          expo.updated.value = true;
          expo.toggleModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.cursor-move {
  cursor: move;
}
</style>
