<template>
  <div>
    <b-modal ref="my-modal" size="lg" class="rounded-0" centered title="Bölgeler Güncelle">
      <b-card-text>
        <b-row v-if="updated">
          <b-col cols="12">
            <b-form-group label-cols="5" label="Gösterim Sayısı">
              <b-form-input v-model="form.ayar.gosterim_sayisi" type="number" min="3" max="9" class="rounded-0" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-cols="5" label="Gösterim Seçiniz">
              <v-select
                v-model="form.ayar.mod"
                :options="sliders"
                :reduce="(slide) => slide.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Bölge</th>
                  <th scope="col">Grid Boyut</th>
                  <th scope="col">Mod</th>
                  <th scope="col" class="text-right">
                    <b-button squared size="sm" @click="handlerAddGrip" variant="success"><i class="fad fa-plus"></i></b-button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in form.ayar.grid_type" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td style="width: 200px">
                    <treeselect
                      :options="bolgeler"
                      v-model="item.bolge_k_no"
                      :normalizer="normalizer"
                      :flat="true"
                      :sort-value-by="sortValueBy"
                      :default-expand-level="1"
                      noChildrenText="Alt Öge Yok."
                      :placeholder="'Bölge'"
                      aria-describedby="input-1-live-feedback"
                      class="rounded-0"
                      :multiple="false"
                      :max-height="200"
                      style="width: 200px"
                    />
                  </td>
                  <td>
                    <b-form-input class="rounded-0" v-model="item.grid" size="lg" />
                  </td>
                  <td>
                    <v-select
                      v-model="item.mod"
                      :options="gridOzellik"
                      :reduce="(slide) => slide.id"
                      label="title"
                      :clearable="true"
                      class="invoice-filter-select d-block select-size-lg"
                    />
                  </td>
                  <td class="text-right">
                    <b-button squared variant="danger" size="lg" @click="handlerRemove(index)">
                      <i class="fad fa-trash"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="$emit('save', true)">
            <i class="fad fa-save" />
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { ref, computed, toRefs, watch } from '@vue/composition-api';
import store from '@/store';
export default {
  components: {
    vSelect,
    Treeselect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    const { form } = toRefs(props);
    expo.updated = ref(false);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.bolgeler = computed(() => store.getters.getBolgeler);

    expo.sortValueBy = ref('INDEX');

    expo.normalizer = (node) => {
      return {
        id: node.k_no && node.k_no,
        label: node.icerik != undefined && node.icerik[expo.defaultDil.value].baslik,
        children: node.children != undefined && node.children.length > 0 && node.children,
      };
    };

    expo.sliders = ref([
      {
        id: 'slide',
        title: 'Slide Show',
      },
      {
        id: 'grid',
        title: 'Grid Gösterim',
      },
    ]);

    expo.gridOzellik = ref([
      {
        id: 'resim',
        title: 'Sadece Resim',
      },
      {
        id: 'resim_ust_yazi',
        title: 'Resim Üst Yazı',
      },
      {
        id: 'resim_grid_yazi',
        title: 'Resim Grid Yazı',
      },
    ]);
    expo.toggleModal = () => {
      context.refs['my-modal'].toggle('#toggle-btn');
      context.emit('closeModal', true);
    };

    expo.handlerAddGrip = () => {
      const grid = {
        bolge_k_no: null,
        grid: 'col-md-6',
        mod: 'resim', //resim,resim_yazi,resim_grid
      };

      if (form.value.ayar.grid_type.length < parseInt(form.value.ayar.gosterim_sayisi)) form.value.ayar.grid_type.push(grid);
    };

    expo.handlerRemove = (index) => {
      form.value.ayar.grid_type.splice(index, 1);
    };

    watch(
      props,
      (val) => {
        if (val.openModal == true) {
          expo.updated.value = true;
          expo.toggleModal();
        }
      },
      { deep: true }
    );

    watch(form, (val) => {}, { deep: true });

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
