<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-start">
        <div class="mr-2 pr-1" v-if="modul.ayar">
          <i class="fad fa-cog cursor-pointer" size="22" @click="$emit('openModal', modul.modul)" />
        </div>
        <div>
          <strong>{{ modul.title }}</strong>
        </div>
      </div>
      <div>
        <b-form-checkbox :checked="modul.statu" v-model="modul.statu" switch size="lg" />
      </div>
    </div>
  </b-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
export default {
  props: {
    modul: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.cursor-move {
  cursor: move;
  font-size: 20px;
}
.cursor-pointer {
  cursor: pointer;
  font-size: 20px;
}
</style>
