<template>
  <div class="list-card">
    <div class="list-card-left">
      <div class="list-card-header">
        <slot name="leftAction" />
      </div>
      <div class="list-card-body">
        <b-overlay id="overlay-background" :show="attrs['show']" variant="light" :opacity="0.8" blur="2px" rounded="sm">
          <slot name="leftBody" />
        </b-overlay>
      </div>
    </div>
    <div class="list-card-right">
      <div class="list-card-overlay" />
      <div class="list-card-header">
        <div class="d-flex align-items-center">
          <i class="pl-3 fad fa-bars fa-2x toggle-list-menu" />
          <h5>{{ attrs['title'] }}</h5>
        </div>
        <slot name="rightAction" />
      </div>
      <div class="list-card-body">
        <slot name="rightBody" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, useAttrs, onMounted } from '@vue/composition-api';

export default defineComponent({
  inheritAttrs: false,
  setup() {
    const expo = {};
    expo.attrs = useAttrs();
    expo.search = ref(null);

    onMounted(() => {
      const listCardLeft = document.querySelector('.list-card-left');
      const toggleMenu = document.querySelector('.toggle-list-menu');
      const listCardOverlay = document.querySelector('.list-card-overlay');

      toggleMenu.addEventListener('click', toogleNav);
      listCardOverlay.addEventListener('click', toogleNav);
      function toogleNav() {
        listCardOverlay.classList.toggle('active');
        listCardLeft.classList.toggle('open-menu');
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
