<template>
  <div class="p-2">
    <b-row>
      <b-col cols="12">
        <ColorPickers :label="'Arka Plan Rengi'" :color="form.arkaplan_rengi" @color="form.arkaplan_rengi = $event" />
      </b-col>
      <b-col cols="12">
        <ColorPickers :label="'Top Bar Rengi'" :color="form.top_bar" @color="form.top_bar = $event" />
      </b-col>
      <b-col cols="12">
        <ColorPickers :label="'Nav Bar Rengi'" :color="form.nav_bar" @color="form.nav_bar = $event" />
      </b-col>
      <b-col cols="12">
        <ColorPickers :label="'Border Rengi'" :color="form.border_renk" @color="form.border_renk = $event" />
      </b-col>
      <b-col cols="12">
        <ColorPickers :label="'Yazı Rengi'" :color="form.yazi_renk" @color="form.yazi_renk = $event" />
      </b-col>
      <b-col cols="12">
        <b-form-group label="Slider Animasyon">
          <v-select
            v-model="form.slider_boyut"
            :options="sliderBoyut"
            :reduce="(boyut) => boyut.value"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block select-size-lg"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Slider Animasyon">
          <v-select
            v-model="form.slider_animasyon"
            :options="sliderAnimasyon"
            :reduce="(ani) => ani.value"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block select-size-lg"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Slider Değişim Süresi">
          <b-form-input size="lg" class="rounded-0" type="number" v-model="form.slider_zaman" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <div class="d-flex justify-content-between align-items-center py-3">
          <label for="statu" class="h6">Slider Otomatik Oynatma</label>
          <b-form-checkbox :checked="form.slider_otomatik_oynatma" v-model="form.slider_otomatik_oynatma" switch size="lg" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ColorPickers from '@/components/colorPicker/ColorPickers.vue';
export default defineComponent({
  components: {
    vSelect,
    ColorPickers,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    expo.sliderBoyut = ref([
      {
        value: 'container_fuild',
        title: 'Container Fuild',
      },
      {
        value: 'container',
        title: 'Container',
      },
    ]);

    expo.sliderAnimasyon = ref([
      {
        value: 'animate__fadeIn',
        title: 'Animate FadeIn',
      },
      {
        value: 'animate__fadeOut',
        title: 'Animate FadeOut',
      },
      {
        value: 'animate__bounce',
        title: 'Animate Bounce',
      },
      {
        value: 'animate__bounceOut',
        title: 'Animate Bounce Out',
      },
      {
        value: 'animate__jello',
        title: 'Animate Jello',
      },
      {
        value: 'animate__lightSpeedInRight',
        title: 'Animate Light Speed In Right',
      },
      {
        value: 'animate__flip',
        title: 'Animate Flip',
      },
    ]);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
