<template>
  <div>
    <b-modal ref="my-modal" class="rounded-0" no-close-on-backdrop centered title="Hakkımızda Güncelle">
      <b-card-text>
        <b-row v-if="updated">
          <b-col cols="12">
            <b-form-group label-cols="5" label="Sayfa Türünü Seçiniz.">
              <v-select
                v-model="form.ayar.sayfa_k_no"
                :options="sayfalar"
                :reduce="(sayfa) => sayfa.k_no"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" @click="$emit('save', true)">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" />
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    expo.updated = ref(false);
    expo.sayfalar = ref([]);

    const handlerGetAllData = async () => {
      expo.sayfalar.value = [];
      await store.dispatch('sayfaListele').then((res) => {
        if (res.data.success == true) {
          const items = res.data.data;
          items.map((el) => {
            expo.sayfalar.value.push({
              k_no: el.k_no,
              title: el.icerik['tr'].baslik,
            });
          });
        }
      });
    };

    expo.toggleModal = () => {
      context.refs['my-modal'].toggle('#toggle-btn');
      context.emit('closeModal', true);
    };
    watch(
      props,
      (val) => {
        if (val.openModal == true) {
          expo.updated.value = true;
          handlerGetAllData();
          expo.toggleModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
