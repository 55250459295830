<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-start">
        <div class="mr-2 pr-1" v-if="disabled(modul.modul)">
          <i class="fad fa-arrows cursor-move" :class="disabled(modul.modul) && 'draggable-task-handle'" size="22" />
        </div>
        <div class="mr-2 pr-1" v-if="modul.ayar">
          <i class="fad fa-cog cursor-pointer" size="22" @click="$emit('openModal', modul.modul)" />
        </div>
        <div>
          <strong class="d-block">{{ modul.title }}</strong>
          <small v-if="modul.ayar != null && Object.keys(modul.ayar) != undefined && Object.keys(modul.ayar).includes('icerik')">
            {{ modul.ayar.icerik['tr'].baslik }}
          </small>
        </div>
      </div>
      <div>
        <b-form-checkbox :checked="modul.statu" v-model="modul.statu" switch size="lg" />
      </div>
    </div>
  </b-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
export default {
  props: {
    modul: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    expo.disabled = (val) => {
      return val == 'header_topbar'
        ? false
        : val == 'header_navbar'
        ? false
        : val == 'slider'
        ? false
        : val == 'footer'
        ? false
        : true;
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.cursor-move {
  cursor: move;
  font-size: 20px;
}
.cursor-pointer {
  cursor: pointer;
  font-size: 20px;
}
</style>
