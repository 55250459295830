<template>
  <div class="p-3">
    <draggable v-model="form.anasayfa_moduller" handler=".draggable-task-handle" draggable=".item">
      <transition-group type="transition" name="flip-list">
        <AnasayfaComItem
          v-for="(item, i) in form.anasayfa_moduller"
          :key="i + 0"
          :modul="item"
          @openModal="handlerOpenModal($event, i)"
          :class="disabled(item.modul) ? 'item border-primary' : ''"
          class="my-2"
        />
      </transition-group>
    </draggable>
    <!-- {{ form.anasayfa_moduller }} -->
    <AyarTopBarHeader
      :openModal="openTopBarHeaderModal"
      :form="form.anasayfa_moduller[selectModulIndex]"
      @save="$emit('handlerOnSubmit', true)"
      @closeModal="closeModal = $event"
    />
    <AyarNavBarHeader
      :openModal="openNavBarHeaderModal"
      :form="form.anasayfa_moduller[selectModulIndex]"
      @save="$emit('handlerOnSubmit', true)"
      @closeModal="closeModal = $event"
    />
    <AyarKolleksiyonlar
      :openModal="openKolleksiyonlarModal"
      :form="form.anasayfa_moduller[selectModulIndex]"
      @save="$emit('handlerOnSubmit', true)"
      @closeModal="closeModal = $event"
    />
    <AyarVitrinUrunleri1
      :openModal="openVitrinUrunler1Modal"
      :form="form.anasayfa_moduller[selectModulIndex]"
      @save="$emit('handlerOnSubmit', true)"
      @closeModal="closeModal = $event"
    />
    <AyarVitrinUrunleri2
      :openModal="openVitrinUrunler2Modal"
      :form="form.anasayfa_moduller[selectModulIndex]"
      @save="$emit('handlerOnSubmit', true)"
      @closeModal="closeModal = $event"
    />
    <AyarFirsatUrunleri
      :openModal="openFirsatUrunlerModal"
      :form="form.anasayfa_moduller[selectModulIndex]"
      @save="$emit('handlerOnSubmit', true)"
      @closeModal="closeModal = $event"
    />
    <AyarInfo
      :openModal="openInfoModal"
      :form="form.anasayfa_moduller[selectModulIndex]"
      @save="$emit('handlerOnSubmit', true)"
      @closeModal="closeModal = $event"
    />
    <AyarCozumOrtaklarimiz
      :openModal="openCozumOrtaklariModal"
      :form="form.anasayfa_moduller[selectModulIndex]"
      @save="$emit('handlerOnSubmit', true)"
      @closeModal="closeModal = $event"
    />
    <AyarBolgeler
      :openModal="openBolgelerModal"
      :form="form.anasayfa_moduller[selectModulIndex]"
      @save="$emit('handlerOnSubmit', true)"
      @closeModal="closeModal = $event"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api';
import draggable from 'vuedraggable';
import AnasayfaComItem from './AnasayfaComItem.vue';
import AyarNavBarHeader from './AyarNavBarHeader.vue';
import AyarTopBarHeader from './AyarTopBarHeader.vue';
import AyarKolleksiyonlar from './AyarKolleksiyonlar';
import AyarVitrinUrunleri1 from './AyarVitrinUrunleri1.vue';
import AyarVitrinUrunleri2 from './AyarVitrinUrunleri2.vue';
import AyarFirsatUrunleri from './AyarFirsatUrunleri.vue';
import AyarInfo from './AyarInfo.vue';
import AyarCozumOrtaklarimiz from './AyarCozumOrtaklarimiz.vue';
import AyarBolgeler from './AyarBolgeler.vue';
export default defineComponent({
  components: {
    draggable,
    AnasayfaComItem,
    AyarTopBarHeader,
    AyarNavBarHeader,
    AyarKolleksiyonlar,
    AyarVitrinUrunleri1,
    AyarVitrinUrunleri2,
    AyarFirsatUrunleri,
    AyarInfo,
    AyarCozumOrtaklarimiz,
    AyarBolgeler,
  },
  props: {
    form: [Object],
  },
  setup(props) {
    const expo = {};

    expo.closeModal = ref(false);
    expo.openTopBarHeaderModal = ref(false);
    expo.openNavBarHeaderModal = ref(false);
    expo.openKolleksiyonlarModal = ref(false);
    expo.openVitrinUrunler1Modal = ref(false);
    expo.openVitrinUrunler2Modal = ref(false);
    expo.openFirsatUrunlerModal = ref(false);
    expo.openInfoModal = ref(false);
    expo.openCozumOrtaklariModal = ref(false);
    expo.openTanitimVideoModal = ref(false);
    expo.openUrunTanitimiModal = ref(false);
    expo.openBlogModal = ref(false);
    expo.openIstatiklerModal = ref(false);
    expo.openTavsiyeUrunlerModal = ref(false);
    expo.openMisafirYorumlariModal = ref(false);
    expo.openAboneOlModal = ref(false);
    expo.openFooterModal = ref(false);
    expo.openBolgelerModal = ref(false);
    expo.selectModulIndex = ref(0);

    expo.disabled = (val) => {
      return val == 'header_topbar'
        ? false
        : val == 'header_navbar'
        ? false
        : val == 'slider'
        ? false
        : val == 'footer'
        ? false
        : true;
    };

    expo.handlerOpenModal = (event, i) => {
      expo.selectModulIndex.value = i;
      if (event == 'header_topbar') {
        expo.openTopBarHeaderModal.value = true;
      } else if (event == 'header_navbar') {
        expo.openNavBarHeaderModal.value = true;
      } else if (event == 'kolleksiyonlar') {
        expo.openKolleksiyonlarModal.value = true;
      } else if (event == 'vitrin_urunleri_1') {
        expo.openVitrinUrunler1Modal.value = true;
      } else if (event == 'vitrin_urunleri_2') {
        expo.openVitrinUrunler2Modal.value = true;
      } else if (event == 'firsat_urunleri') {
        expo.openFirsatUrunlerModal.value = true;
      } else if (event == 'info') {
        expo.openInfoModal.value = true;
      } else if (event == 'cozum_ortaklarimiz') {
        expo.openCozumOrtaklariModal.value = true;
      } else if (event == 'tanitim_video') {
        expo.openTanitimVideoModal.value = true;
      } else if (event == 'urunler') {
        expo.openUrunTanitimiModal.value = true;
      } else if (event == 'blog') {
        expo.openBlogModal.value = true;
      } else if (event == 'istatistikler') {
        expo.openIstatiklerModal.value = true;
      } else if (event == 'temsilci_tavsiyesi') {
        expo.openTavsiyeUrunlerModal.value = true;
      } else if (event == 'yorumlar') {
        expo.openMisafirYorumlariModal.value = true;
      } else if (event == 'abone_ol') {
        expo.openAboneOlModal.value = true;
      } else if (event == 'footer') {
        expo.openFooterModal.value = true;
      } else if (event == 'anasayfa_bolgeler') {
        expo.openBolgelerModal.value = true;
      }
    };

    watch(expo.closeModal, (val) => {
      expo.closeModal.value = false;
      expo.openTopBarHeaderModal.value = false;
      expo.openNavBarHeaderModal.value = false;
      expo.openKolleksiyonlarModal.value = false;
      expo.openVitrinUrunler1Modal.value = false;
      expo.openVitrinUrunler2Modal.value = false;
      expo.openFirsatUrunlerModal.value = false;
      expo.openInfoModal.value = false;
      expo.openCozumOrtaklariModal.value = false;
      expo.openTanitimVideoModal.value = false;
      expo.openUrunTanitimiModal.value = false;
      expo.openBlogModal.value = false;
      expo.openIstatiklerModal.value = false;
      expo.openTavsiyeUrunlerModal.value = false;
      expo.openMisafirYorumlariModal.value = false;
      expo.openAboneOlModal.value = false;
      expo.openFooterModal.value = false;
      expo.openBolgelerModal.value = false;
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
