import { render, staticRenderFns } from "./AnasayfaComItem.vue?vue&type=template&id=48e702e4&scoped=true&"
import script from "./AnasayfaComItem.vue?vue&type=script&lang=js&"
export * from "./AnasayfaComItem.vue?vue&type=script&lang=js&"
import style0 from "./AnasayfaComItem.vue?vue&type=style&index=0&id=48e702e4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e702e4",
  null
  
)

export default component.exports