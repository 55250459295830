<template>
  <div class="p-3">
    <KategoriComItem
      v-for="(item, i) in form.kategori_moduller"
      :key="i + 0"
      :modul="item"
      @openModal="handlerOpenModal($event, i)"
      class="my-2 border-primary"
    />

    <KategoriFiltreleme
      :openModal="openFiltrelemeModal"
      :form="form.kategori_moduller[selectModulIndex]"
      :variantlar="variantlar"
      @save="$emit('handlerOnSubmit', true)"
      @yeniOzellikEkle="handlerYeniOzellikEkle($event)"
      @closeModal="closeModal = $event"
    />
  </div>
</template>

<script>
import store from '@/store';
import { ref, watch, defineComponent, toRefs, onMounted } from '@vue/composition-api';
import KategoriComItem from './KategoriComItem.vue';
import KategoriFiltreleme from './KategoriFiltreleme.vue';
export default defineComponent({
  components: { KategoriComItem, KategoriFiltreleme },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, cnxt) {
    const expo = {};

    const { form } = toRefs(props);
    expo.variantlar = ref([]);
    expo.selectModulIndex = ref(0);
    expo.openFiltrelemeModal = ref(false);
    expo.closeModal = ref(false);

    expo.handlerOpenModal = (event, i) => {
      expo.selectModulIndex.value = i;
      if (event == 'filtreleme') {
        expo.openFiltrelemeModal.value = true;
      }
    };

    expo.handlerYeniOzellikEkle = (event) => {
      const variant = expo.variantlar.value.find((x) => x.k_no == event);

      form.value.kategori_moduller[0].ayar.push({
        modul: event,
        title: variant.icerik['tr'].baslik,
        slug: 'variant',
        type: 'checkbox',
        statu: true,
      });
    };

    const handlerGetData = async () => {
      cnxt.emit('show', true);
      await store.dispatch('variantGrupListele');
      expo.variantlar.value = store.getters.getVariantGruplari;
      cnxt.emit('show', false);
    };

    handlerGetData();

    watch(expo.closeModal, (val) => {
      expo.closeModal.value = false;
      expo.openFiltrelemeModal.value = false;
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
