<template>
  <BNormalActions :show="show">
    <template v-slot:leftAction>
      <v-select
        v-model="tasarim"
        :options="tasarimSayfalari"
        :reduce="(statu) => statu.value"
        label="title"
        :clearable="false"
        style="min-width: 400px"
        class="invoice-filter-select w-100 d-block select-size-lg"
      />
    </template>
    <template v-slot:rightAction>
      <b-button squared variant="success" @click="onSubmit"> <i class="fad fa-save"></i> Kaydet</b-button>
    </template>
    <template v-slot:leftBody>
      <GenelGorunum v-if="tasarim == 'genel_page'" :form="form" />
      <Anasayfa v-else-if="tasarim == 'home_page'" :form="form" @handlerOnSubmit="onSubmit" />
      <Kategori v-else-if="tasarim == 'categories'" :form="form" @handlerOnSubmit="onSubmit" @show="show = $event" />
    </template>
    <template v-slot:rightBody>
      <iframe
        id="sites"
        name="sites"
        src="http://localhost:3005"
        :allowfullscreen="true"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </template>
  </BNormalActions>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref } from '@vue/composition-api';
import BNormalActions from '@/components/cards/BNormalActions.vue';
import GenelGorunum from './component/GenelGorunum.vue';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import Anasayfa from './component/Anasayfa.vue';
import Kategori from './component/Kategori.vue';
export default defineComponent({
  components: {
    vSelect,
    BNormalActions,
    GenelGorunum,
    Anasayfa,
    Kategori,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);

    expo.tasarim = ref('genel_page');

    expo.tasarimSayfalari = ref([
      {
        value: 'genel_page',
        title: 'Genel Görünüm',
      },
      {
        value: 'home_page',
        title: 'Anasayfa',
      },
      {
        value: 'categories',
        title: 'Kategori',
      },
    ]);

    expo.form = ref({
      k_no: null,
      arkaplan_rengi: '#ffffff',
      top_bar: '#ffffff',
      nav_bar: '#ffffff',
      border_renk: '#ffffff',
      yazi_renk: '#ffffff',
      slider_boyut: 'container_fuild',
      slider_animasyon: 'animate__fadeIn',
      slider_zaman: 8000,
      slider_otomatik_oynatma: false,
      anasayfa_moduller: [
        {
          modul: 'header_topbar',
          title: 'Header Top Bar ',
          statu: false,
          ayar: {
            telefon: true,
            sosyal: true,
            diller: true,
            kurlar: true,
            giris: true,
            kargo_takip: true,
          },
        },
        {
          modul: 'header_navbar',
          title: 'Header Nav Bar',
          statu: false,
          ayar: {
            logo: true,
            menu: true,
            giris: true,
            diller: true,
            kurlar: true,
            buton: {
              statu: false,
              baslik: '',
              url: '',
            },
          },
        },
        {
          modul: 'slider',
          title: 'Slideshow Alanı',
          statu: false,
        },
        {
          modul: 'filter',
          title: 'Filtreleme Alanı',
          statu: false,
        },
        {
          modul: 'anasayfa_kategori',
          title: 'Anasayfa Kategori',
          statu: false,
        },
        {
          modul: 'anasafya_bolgeler',
          title: 'Anasayfa Bölgeler',
          statu: false,
          ayar: {
            gosterim_sayisi: 9,
            gorunum: 'grid', // slide "yana kayma şekli"
            grid_type: [],
          },
        },
        {
          modul: 'banner_1',
          title: 'Banner Alanı 1',
          statu: false,
        },
        {
          modul: 'banner_2',
          title: 'Banner Alanı 2',
          statu: false,
        },
        {
          modul: 'banner_3',
          title: 'Banner Alanı 3',
          statu: false,
        },
        {
          modul: 'kolleksiyonlar',
          title: 'En İyi Kolleksiyon Alanı',
          statu: false,
          ayar: {
            gosterim_sayisi: 9,
            gorunum: 'grid', // slide "yana kayma şekli"
          },
        },
        {
          modul: 'vitrin_urunleri_1',
          title: 'Vitrin Ürün Alanı 1',
          statu: false,
          ayar: {
            icerik: {
              tr: { baslik: 'Vitrin Ürün Alanı 1', aciklama: null },
              en: { baslik: null, aciklama: null },
              de: { baslik: null, aciklama: null },
              ru: { baslik: null, aciklama: null },
              nl: { baslik: null, aciklama: null },
              ar: { baslik: null, aciklama: null },
            },
            gosterim_sayisi: 9,
            gorunum: 'grid', // slide "yana kayma şekli"
          },
        },
        {
          modul: 'vitrin_urunleri_2',
          title: 'Vitrin Ürün Alanı 2',
          statu: false,
          ayar: {
            icerik: {
              tr: { baslik: 'Vitrin Ürün Alanı 2', aciklama: null },
              en: { baslik: null, aciklama: null },
              de: { baslik: null, aciklama: null },
              ru: { baslik: null, aciklama: null },
              nl: { baslik: null, aciklama: null },
              ar: { baslik: null, aciklama: null },
            },
            gosterim_sayisi: 9,
            gorunum: 'grid', // slide "yana kayma şekli"
          },
        },
        {
          modul: 'firsat_urunleri',
          title: 'Fırsat Ürün Alanı',
          statu: false,
          ayar: {
            icerik: {
              tr: { baslik: 'Fırsat Ürün Alanı', aciklama: null },
              en: { baslik: null, aciklama: null },
              de: { baslik: null, aciklama: null },
              ru: { baslik: null, aciklama: null },
              nl: { baslik: null, aciklama: null },
              ar: { baslik: null, aciklama: null },
            },
            gosterim_sayisi: 9,
            gorunum: 'grid', // slide "yana kayma şekli"
          },
        },
        {
          modul: 'cozum_ortaklarimiz',
          title: 'Çözüm Ortaklarımız',
          statu: false,
          ayar: {
            sayfa_k_no: null,
          },
        },
        {
          modul: 'info',
          title: 'İnfo Alanı',
          statu: false,
          ayar: {
            sayfa_k_no: null,
          },
        },
        {
          modul: 'tanitim_video',
          title: 'Tanıtım Video Alanı',
          statu: false,
          ayar: {
            url: null,
          },
        },
        {
          modul: 'istatistik',
          title: 'Web Site İstatistik Alanı',
          statu: false,
        },

        {
          modul: 'yorumlar',
          title: 'Misafir Yorum Alanı',
          statu: false,
          ayar: {
            gosterim_sayisi: 9,
            gosterim: 'rasgele', //rasgele, en_son_eklenen, puan_yuksek_olan,
          },
        },
        {
          modul: 'abone_ol',
          title: 'Abone Ol Alanı',
          statu: false,
        },
        {
          modul: 'footer',
          title: 'Footer Alanı',
          statu: false,
        },
      ],
      kategori_moduller: [
        {
          modul: 'filtreleme',
          title: 'Filtreleme',
          statu: true,
          ayar: [
            {
              modul: 'arama',
              title: 'Arama Filtreleme',
              slug: 'arama',
              type: 'checkbox',
              statu: true,
            },
            {
              modul: 'kategori',
              title: 'Kategori Filtreleme',
              slug: 'kategoriler',
              type: 'checkbox',
              statu: true,
            },
            {
              modul: 'marka',
              title: 'Marka Filtreleme',
              slug: 'markalar',
              type: 'checkbox',
              statu: true,
            },
            {
              modul: 'fiyat',
              title: 'Fiyat Filtreleme',
              slug: 'fiyat',
              type: 'tags',
              statu: true,
              value: ['0,1000', '1000,5000', '5000,10000', '10000,50000', '50000,100000', '100000,500000'],
            },
            {
              modul: 'begeni',
              title: 'Begenilere Göre',
              slug: 'begeniler',
              type: 'checkbox',
              statu: true,
            },
            {
              modul: 'anket',
              title: 'Antek Gösterme',
              slug: 'anket',
              type: 'select',
              statu: true,
              anket_k_no: null,
            },
          ],
        },
      ],
      sayfa_moduller: [],
      iletisim_moduller: [],
    });

    const handlerConfig = async () => {
      context.emit('show', true);
      await store.dispatch('temaAyarGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });

      await store.dispatch('bolgeListele');
      context.emit('show', false);
    };

    handlerConfig();

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('temaAyarEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            window.document.getElementById('sites').src += '';
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
