<template>
  <b-card no-body class="border-primary p-2 my-2">
    <div class="d-flex justify-content-between align-items-center py-1">
      <div class="d-flex">
        <div class="mr-2 pr-1" v-if="disabled(form.slug)">
          <i
            class="fad fa-arrows cursor-move"
            style="font-size: 20px"
            :class="disabled(form.slug) && 'draggable-task-handle'"
            size="22"
          />
        </div>
        <label for="statu" class="font-weight-bold h6">{{ form.title }}</label>
      </div>
      <b-form-checkbox v-if="form.type == 'checkbox'" :checked="form.statu" v-model="form.statu" switch size="lg" />
      <b-form-tags v-else-if="form.type == 'tags'" size="lg" class="rounded-0" style="width: 500px" v-model="form.value" />
      <v-select
        v-else-if="form.type == 'select' && form.slug == 'anket'"
        v-model="form.value"
        :options="anketler"
        :reduce="(boyut) => boyut.value"
        label="title"
        :clearable="false"
        class="invoice-filter-select d-block select-size-lg"
        style="min-width: 300px"
      />
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import { ref, watch } from '@vue/composition-api';
export default {
  components: {
    vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    expo.updated = ref(false);
    expo.secilen_variant = ref(null);
    expo.anketler = ref([]);

    expo.disabled = (val) => {
      return val == 'arama' ? false : true;
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.cursor-move {
  cursor: move;
}
</style>
